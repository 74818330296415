import React, { useState , useRef,useEffect } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';
import emailjs from '@emailjs/browser';
import { BsLinkedin, BsGithub,BsFillPhoneFill} from 'react-icons/bs';
import {SiGooglescholar} from 'react-icons/si'
import {GrMail} from 'react-icons/gr';
import Interview from "./interview.svg";
import Connect from "./connect.svg";


const Footer = () => {

  const [isLoaded, setIsLoaded] = useState(true);


  const [loading, setLoading] = useState(false);



  const handleSubmit = () => {
    setLoading(true);







  };
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
  });
  
  

  return isLoaded ? (
      <>
      
      <TheFooter/>
      <Email/>
        <div className="footer-container">

      </div>
        <div className="newapp__social">
          <div>
          <a href="mailto:misterbrandonwalker@gmail.com">
          <GrMail />
          </a>
          </div>
        
          <div>
          <a href="https://github.com/misterbrandonwalker">
          <BsGithub />
          </a>
          </div>
          <div>
          <a href="https://tinyurl.com/35s7ybu6">
          <SiGooglescholar />
          </a>
          </div>
          <div>
          <a href="https://www.linkedin.com/in/misterbrandonwalker/">
          <BsLinkedin />
          </a>
          </div>

        </div>

      </>
  ) : <></>


};

function TheFooter() {

  return (
    <>
    <h2 className="head-text">Lets Connect</h2>
    <div className='images'>
    <img className='footerimage' src={Connect}/>
    <img className='footerimage' src={Interview}/>
    </div>
    <div className={'newcontainer'}>
        <div className={'coffee-header'}>
          <div className={'coffee-header__buttons coffee-header__button-one'}></div>
          <div className={'coffee-header__buttons coffee-header__button-two'}></div>
          <div className={'coffee-header__display'}></div>
          <div className={'coffee-header__details'}></div>
        </div>
        <div className={'coffee-medium'}>
          <div className={'coffe-medium__exit'}></div>
          <div className={'coffee-medium__arm'}></div>
          <div className={'coffee-medium__liquid'}></div>
          <div className={'coffee-medium__smoke coffee-medium__smoke-one'}></div>
          <div className={'coffee-medium__smoke coffee-medium__smoke-two'}></div>
          <div className={'coffee-medium__smoke coffee-medium__smoke-three'}></div>
          <div className={'coffee-medium__smoke coffee-medium__smoke-for'}></div>
          <div className={'coffee-medium__cup'}></div>
        </div>
        <div className={'coffee-footer'}></div>
      </div>


    </>
  );
}


function Email() {
  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_z2s3xnk",
        form.current,
        "TvLuvvBjJQSljJKtB"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );

  };
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
  setLoading(true);

  const contact = {
    _type: 'contact',
    name: formData.username,
    email: formData.email,
    message: formData.message,
  };

  client.create(contact)
    .then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch((err) => console.log(err));



};

  return (

    <>
    <form className="form-inline" ref={form} onSubmit={sendEmail}>
    {!isFormSubmitted ? (
       <div className="app__footer-form app__flex">
         <div className="app__flex">
           <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
         </div>
         <div className="app__flex">
           <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
         </div>
         <div>
           <textarea
             className="p-text"
             placeholder="Your Message"
             value={message}
             name="message"
             onChange={handleChangeInput}
           />
         </div>
         <button type="submit" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
       </div>
     ) : (
       <div>
         <h3 className="ahead-text">
           Thank you for getting in touch!
         </h3>
       </div>
     )}
     </form>


    </>
  );
};




export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
