import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Research.scss';
import Articles from "./articles.svg";


const Research = () => {
  const scrollRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [papers, setPapers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [papersPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [publications, setPublications] = useState([]);
  const [activeFilter, setActiveFilter] = useState('First Author');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterPublication, setFilterPublication] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [originalPapers, setOriginalPapers] = useState([]);
  const [maxResults] = useState(4);
  
  
  const keywordGroups = [
    {
      category: 'Binding & Affinity',
      keywords: [
        'Protein-ligand interactions',
        'Binding affinity',
        'Ligand-receptor binding',
      ]
    },
    {
      category: 'Drug Discovery & Design',
      keywords: [
        'Drug design',
        'Virtual screening',
        'Lead optimization',
      ]
    },
    {
      category: 'Molecular Simulations',
      keywords: [
        'Molecular dynamics',
        'Molecular docking',
        'Computational chemistry',
      ]
    },
    {
      category: 'Pharmacodynamics',
      keywords: [
        'Free energy perturbation',
        'ADMET',
        'Pharmacophore modeling',
      ]
    }
  ];

  // Logic for showing a max of 5 dots
  const maxDots = 5;
  let startPage = Math.max(1, currentPage - Math.floor(maxDots / 2));
  let endPage = Math.min(totalPages, startPage + maxDots - 1);

  if (endPage - startPage < maxDots - 1) {
    startPage = Math.max(1, endPage - maxDots + 1);
  }

  // Debounced hover handler
  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  // Fetch publications
  useEffect(() => {
    const query = '*[_type == "publications"]';
    client.fetch(query).then((data) => {
      setPublications(data);
      setFilterPublication(data.filter((item) => item.tags.includes(activeFilter)));
    });
  }, [activeFilter]);

  useEffect(() => {
    setTotalPages(Math.ceil(papers.length / papersPerPage)); // Use the updated papersPerPage value
  }, [papers, papersPerPage]); 

  // Fetch papers from arXiv
  useEffect(() => {
    const fetchPapers = async () => {
      let allPapers = [];
    
      await Promise.all(
        keywordGroups.flatMap((group) =>
          group.keywords.map(async (keyword) => {
            const query = `all:"${keyword}"`;
            const response = await fetch(
              `https://export.arxiv.org/api/query?search_query=${query}&sortBy=submittedDate&sortOrder=descending&max_results=${maxResults}`
            );
            const data = await response.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'application/xml');
            const entries = xmlDoc.getElementsByTagName('entry');
    
            let keywordPapers = [];
            for (let i = 0; i < entries.length; i++) {
              const entry = entries[i];
              keywordPapers.push({
                title: entry.getElementsByTagName('title')[0].textContent,
                summary: entry.getElementsByTagName('summary')[0].textContent,
                link: entry.getElementsByTagName('id')[0].textContent,
                published: entry.getElementsByTagName('published')[0].textContent,
                keyword: keyword,
              });
            }
    
            allPapers = [...allPapers, ...keywordPapers];
          })
        )
      );
    
      setPapers(allPapers);
      setOriginalPapers(allPapers);
      setTotalPages(Math.ceil(allPapers.length / papersPerPage));
    };
  
    fetchPapers();
  }, []);

  // Handle publication filter
  const handlePublicationFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);
  
    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
      
      // Add debug log to check the filtering state
      console.log("Active Filter: ", item);
      if (item === 'All') {
        setFilterPublication(publications);
      } else {
        setFilterPublication(publications.filter((publication) => publication.tags.includes(item)));
      }
    }, 500);
  };
  

  // Handle group selection
  const handleGroupSelection = (group) => {
    setSelectedGroup(group); // Set the selected group
  
    if (!group) {
      // If no group is selected, show all papers
      setPapers(originalPapers); // Assuming you have an `originalPapers` state that holds all papers initially
    } else {
      // Filter papers based on the selected group's keywords
      const filteredPapers = originalPapers.filter(paper => group.keywords.includes(paper.keyword));
      setPapers(filteredPapers); // Update papers to show only the selected group's papers
    }
    setCurrentPage(1); // Reset to the first page whenever a new group is selected
  };
  

  // Get current papers to display
  const indexOfLastPaper = currentPage * papersPerPage;
  const indexOfFirstPaper = indexOfLastPaper - papersPerPage;
  const currentPapers = papers.slice(indexOfFirstPaper, indexOfLastPaper);

  return (
    <>
      <h2 className="head-text">Research</h2>
      <img className="articles" src={Articles} alt="Articles" />
      <div className="app__publication-filter">
        {['First Author', 'Co-Author', 'Review'].map((item, index) => (
          <div
            key={index}
            onClick={() => handlePublicationFilter(item)}
            className={`app__publication-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      {/* Research Publications */}
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.25, delayChildren: 0.25 }}
        className="app__publication-portfolio"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        ref={scrollRef}
      >
        {filterPublication.map((publication, index) => (
          <div className="app__publication-item app__flex" key={index}>
            <div className="app__publication-img app__flex">
              <img src={urlFor(publication.imgUrl)} alt={publication.name} />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__publication-hover app__flex"
              >
                <a href={publication.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__publication-content app__flex">
              <h4 className="bold-text">{publication.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {publication.description}
              </p>
            </div>
          </div>
        ))}
      </motion.div>

      {/* Latest Research */}
      <h2 className="head-text">Latest Research</h2>
      {/* Group Selection Buttons */}
<div className="group-selection">
  {keywordGroups.map((group, index) => (
    <button
      key={index}
      onClick={() => handleGroupSelection(group)}
      className={`latest-research-button ${selectedGroup === group ? 'active' : ''}`}
    >
      {group.category}
    </button>
  ))}
</div>

      <motion.div
  className="app__publication-portfolio"
  onMouseOver={handleMouseOver}
  onMouseOut={handleMouseOut}
  ref={scrollRef}
>
  {currentPapers
    .filter((paper) => {
      if (!selectedGroup) return true; // Show all if no group is selected
      return selectedGroup.keywords.includes(paper.keyword);
    }).map((paper, index) => (
    <div
      className="app__publication-item app__flex"
      key={index}
      style={{
        display: selectedGroup && !selectedGroup.keywords.includes(paper.keyword) ? 'none' : 'block',
      }}
    >
      <div className="app__publication-content app__flex">
        <h4 className="bold-text">{paper.title}</h4>
        {isHovering ? (
          <p className="p-text" style={{ marginTop: 10 }}>
            {paper.summary}
          </p>
        ) : (
          <p className="newp-text" style={{ marginTop: 10 }}>
            {truncateText(paper.summary, 350)}
          </p>
        )}
        <div className="app__publication-tag app__flex">
          <p className="p-text">{new Date(paper.published).toLocaleDateString()}</p>
        </div>
        <a href={paper.link} target="_blank" rel="noreferrer">
          <motion.div
            whileInView={{ scale: [0, 1] }}
            whileHover={{ scale: [1, 0.9] }}
            transition={{ duration: 0.25 }}
            className="app__flex"
          >
            <AiFillEye />
          </motion.div>
        </a>
      </div>
    </div>
  ))}
</motion.div>


      <div className="pagination-dots">
        {Array.from({ length: totalPages }, (_, index) => {
          const pageNum = index + 1;
          if (pageNum >= startPage && pageNum <= endPage) {
            return (
              <div
                key={pageNum}
                className={`dot ${currentPage === pageNum ? 'active' : ''}`}
                onClick={() => setCurrentPage(pageNum)}
              ></div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Research, 'app__publications'),
  'research',
  'app__whitebg',
);