import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';
import TagCloud from 'react3dtagcloud';
import WebDev from "./webdev.svg";



const Skills = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});

  const [miscskills, setmiscSkills] = useState([]);
  const [mdskills, setmdSkills] = useState([]);
  const [quantumchemskills, setquantumchemSkills] = useState([]);
  const [chemskills, setchemSkills] = useState([]);
  const [dataskills, setDataSkills] = useState([]);
  useEffect(() => {

    const miscskillsQuery = '*[_type == "miscskills"]';
    client.fetch(miscskillsQuery).then((data) => {
      setmiscSkills(data);
    });

    const mdskillsQuery = '*[_type == "mdskills"]';
    client.fetch(mdskillsQuery).then((data) => {
      setmdSkills(data);
    });

    const quantumchemskillsQuery = '*[_type == "quantumchemskills"]';
    client.fetch(quantumchemskillsQuery).then((data) => {
      setquantumchemSkills(data);
    });
    

    const chemskillsQuery = '*[_type == "chemskills"]';
    client.fetch(chemskillsQuery).then((data) => {
      setchemSkills(data);
    });


    const dataskillsQuery = '*[_type == "dataskills"]';
    client.fetch(dataskillsQuery).then((data) => {
      setDataSkills(data);
    });


  }, []);

  const tagData = [
    { name: 'Javascript', url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript' },
    { name: 'HTML', url: 'https://developer.mozilla.org/en-US/docs/Web/HTML' },
    { name: 'CSS', url: 'https://developer.mozilla.org/en-US/docs/Web/CSS' },
    { name: 'React', url: 'https://reactjs.org/' },
    { name: 'Python', url: 'https://www.python.org/' },
    { name: 'Mathematica', url: 'https://www.wolfram.com/mathematica/' },
    { name: 'Matlab', url: 'https://www.mathworks.com/products/matlab.html' },
    { name: 'Bash', url: 'https://www.gnu.org/software/bash/' },
    { name: 'Git', url: 'https://git-scm.com/' },
    { name: 'SQL', url: 'https://www.w3schools.com/sql/' },
    { name: 'Cypher', url: 'https://neo4j.com/developer/cypher/' }
  ];
  
  // Extract just the names for TagCloud
  const tagNames = tagData.map(tag => tag.name);
  
  // Function to handle clicks on the tag cloud
  const handleTagClick = (event) => {
    event.preventDefault(); // Prevent React's default navigation behavior
    event.stopPropagation(); // Stop event from bubbling up
  
    const clickedTag = event.target.innerText; // Get clicked text
    const tag = tagData.find(tag => tag.name === clickedTag);
    if (tag) {
      window.open(tag.url, '_blank', 'noopener,noreferrer'); // Open URL in a new tab
    }
  };
  
  return (
    <>
      <div id="skills"> 
      <h2 className="head-text">Languages I Speak</h2>
  <div className="container_div">
  <img className='webdev' src={WebDev} alt="Web Development" />

  
    <div className="tagcloud-container">
      <div className="tagcloud" onClick={handleTagClick}> 
        <TagCloud speed={0.1} tagName={tagNames} />
      </div>
    </div>

</div>

    <h2 className="anotherhead-text">Data Science Software</h2>
    <div className="app__skills-container">
      <motion.div className="app__skills-list">
        {dataskills.map((skill) => (
          <a href={skill.website}>
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="app__skills-item app__flex"
            key={skill.name}

          >
            <div
              className="app__flex"
              style={{ backgroundColor: skill.bgColor }}
            >
              <img src={urlFor(skill.icon)} alt={skill.name} />
            </div>
            <p className="p-text">{skill.name}</p>
          </motion.div>
          </a>
        ))}
      </motion.div>
    </div>

    <h2 className="anotherhead-text">Molecular Dynamics Software</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {mdskills.map((skill) => (
            <a href={skill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>

    <h2 className="anotherhead-text">Quantum Chemistry Software</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {quantumchemskills.map((skill) => (
            <a href={skill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>

      <h2 className="anotherhead-text">Chemistry Software</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {chemskills.map((skill) => (
            <a href={skill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>

      <h2 className="anotherhead-text">Miscellaneous Software</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {miscskills.map((skill) => (
            <a href={skill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>

      </div>
    </>
    
  );
};



export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
