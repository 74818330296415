import React, { useState, useEffect } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { motion } from 'framer-motion';
import Typewriter from 'react-typewriter-effect';
import './About.scss';
import quotes from './quotes';  // Import quotes from an external file

const youtubeChannels = [
  { name: "Sabine Hossenfelder", id: "UC1yNl2E66ZzKApQdRuTQ4tw" },
  { name: "PBS Space Time", id: "UC7_gcs09iThXybpVgjHZ_7g" },
  { name: "Two Minute Papers", id: "UCbfYPyITQ-7l4upoX8nvctg" },
  { name: "Kurzgesagt", id: "UCsXVk37bltHxD1rDPwtNM8Q" },
  { name: "Economics Explained", id: "UCZ4AMrDcNrfy3X6nsU8-rPg" },
];

const About = () => {
  const [currentChannelIndex, setCurrentChannelIndex] = useState(0);
  const [latestVideoId, setLatestVideoId] = useState('');
  const [currentQuote, setCurrentQuote] = useState('');
  const [quoteIndex, setQuoteIndex] = useState(Math.floor(Math.random() * quotes.length)); 

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 15000); // Updates every 15 seconds
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentQuote(quotes[quoteIndex]);
  }, [quoteIndex]);

  useEffect(() => {
    const fetchLatestVideo = async () => {
      const channelId = youtubeChannels[currentChannelIndex].id;
      const reqURL = `https://www.youtube.com/feeds/videos.xml?channel_id=${channelId}`;

      try {
        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(reqURL)}`
        );
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          const link = data.items[0].link; 
          const id = link.substr(link.indexOf('v=') + 2); 
          setLatestVideoId(id);
        }
      } catch (error) {
        console.error('Error fetching latest video:', error);
      }
    };

    fetchLatestVideo();
  }, [currentChannelIndex]);

  

  const handleDotClick = (index) => {
    setCurrentChannelIndex(index);
  };

  return (
    <div className="app__personal">
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      style={{ position: 'relative' }} // Ensure it doesn't affect layout
    >
      <div className="bio-container">
        <h2>About Me</h2>
        <div className="bio-text">
          <Typewriter
            textStyle={{
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 300,
              fontSize: '1.4rem',
              color: '#333',
              padding: '0 20px',
              lineHeight: 1.6,
            }}
            startDelay={500}
            cursorColor="black"
            multiText={[
              "I love building things! Driven by curiosity, I focus on innovative solutions that have a real-world impact.",
              "Using Science, Technology, Engineering, and Mathematics to solve problems and shape the future, we can bring science fiction to the real world!"
            ]}
            multiTextDelay={2000}
            typeSpeed={50}
          />
        </div>
      </div>
    </motion.div>
      
      <div className="app__quote-container">
        <motion.div 
          className="app__quote"
          key={currentQuote}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <p>“{currentQuote}”</p>
        </motion.div>
      </div>

      <h2 className="section-title animated-underline">Here are some of my favorite YouTube channels!</h2>

      {/* YouTube Channel Viewer */}
            <div className="youtube-channel-viewer">
        <h3 className="channel-title">{youtubeChannels[currentChannelIndex].name}</h3>
        <div className="video-container">
          {latestVideoId ? (
            <iframe
              src={`https://www.youtube.com/embed/${latestVideoId}?controls=0&showinfo=0&rel=0`}
              title="Latest Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <p>Loading latest video...</p>
          )}
        </div>
        <div className="dots-container">
          {youtubeChannels.map((channel, index) => (
            <span
              key={channel.id}
              className={`dot ${index === currentChannelIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>



    </div>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
