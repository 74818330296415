const quotes = [
  "Simplicity is the ultimate sophistication. – Leonardo da Vinci",
  "The journey of a thousand miles begins with one step. – Lao Tzu",
  "Knowledge speaks, but wisdom listens. – Jimi Hendrix",
  "The only way to do great work is to love what you do. – Steve Jobs",
  "In the middle of every difficulty lies opportunity. – Albert Einstein",
  "What we think, we become. – Buddha",
  "The best way to predict the future is to create it. – Peter Drucker",
  "It is not length of life, but depth of life. – Ralph Waldo Emerson",
  "The unexamined life is not worth living. – Socrates",
  "Do one thing every day that scares you. – Eleanor Roosevelt",
  "Success is not final, failure is not fatal: It is the courage to continue that counts. – Winston Churchill",
  "Life is what happens when you're busy making other plans. – John Lennon",
  "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment. – Ralph Waldo Emerson",
  "Strive not to be a success, but rather to be of value. – Albert Einstein",
  "Act as if what you do makes a difference. It does. – William James",
  "Do not go where the path may lead, go instead where there is no path and leave a trail. – Ralph Waldo Emerson",
  "Success is not how high you have climbed, but how you make a positive difference to the world. – Roy T. Bennett",
  "The mind is everything. What you think you become. – Buddha",
  "Don't watch the clock; do what it does. Keep going. – Sam Levenson",
  "The future belongs to those who believe in the beauty of their dreams. – Eleanor Roosevelt",
];

export default quotes;
