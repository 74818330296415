import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from '../../wrapper';
import { TypeAnimation } from 'react-type-animation';
import './Landing.scss';
import Coding from "./coding.svg";

const Landing = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);


  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);



  return (
    <>
      <div className="app__header">
        <div className="app__header-greeting">
          <h1>Hello, welcome to my corner of the web!</h1>
          
          {/* Animated greeting + about text */}
          <TypeAnimation
            sequence={[
              'Building cool stuff with code.',
              2000,
              'Exploring the world of chemical data science.',
              2000,
              'Engineering chemical properties.',
              2000,
              'Drug Discovery.',
              2000,
              'Let’s make things happen!',
              2000,
            ]}
            speed={50}
            style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333' }}
            repeat={Infinity}
          />
        </div>

        <img className='coding' src={Coding} alt="Coding graphic" />

        <div className="wrapper">
          <div className="loader">
            <div className="wave top-wave">
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="wave bottom-wave">
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default AppWrap(
  MotionWrap(Landing, 'app__landing'),
  'landing',
  'app__whitebg',
);
