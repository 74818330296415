import React, { useState, useEffect } from 'react';
import { Experience, Footer, About, Skills, Research, Summary, Landing } from './container';
import { Navbar } from './components';
import './App.scss';
import { Route, BrowserRouter, Routes } from "react-router-dom";

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Inline style object (if you're using CSS-in-JS)
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    top: "200px"
  };

  return (
    <>
      <BrowserRouter>
        <div className="app">
          <Routes>
            <Route path="/" element={
              <>
                <Navbar />
                <Landing />
                <Experience />
                <Summary />
                <Research />
                <Skills />
                <About />
                <Footer />
              </>
            } />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
