import React, { useRef, useEffect, useState } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
import * as NGL from 'ngl';
import { Pie, Bar, Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, ScatterController  } from 'chart.js';
import { FunnelChart, Funnel, LabelList, ResponsiveContainer, Cell } from "recharts";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, ScatterController);
import './Summary.scss';


const ovalPlugin: Plugin<"scatter"> = {
  id: "drawOvals",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const { width, height } = chart;

    // Define oval properties
    const ovals = [
      { label: "Good ADME", x: width * 0.4, y: height * 0.4, rx: 100, ry: 60, color: "rgba(255, 99, 132, 0.2)", textX: width * 0.4 - 120, textY: height * 0.4 }, // Left of oval
      { label: "Drug Like", x: width * 0.5, y: height * 0.5, rx: 100, ry: 60, color: "rgba(54, 162, 235, 0.2)", textX: width * 0.5, textY: height * 0.5 + 80 }, // Bottom of oval
      { label: "High Affinity", x: width * 0.6, y: height * 0.4, rx: 100, ry: 60, color: "rgba(255, 206, 86, 0.2)", textX: width * 0.6 + 120, textY: height * 0.4 }, // Right of oval
      { label: "Specific", x: width * 0.4, y: height * 0.6, rx: 100, ry: 60, color: "rgba(75, 192, 192, 0.2)", textX: width * 0.4 - 120, textY: height * 0.6 }, // Left of oval
      { label: "Synthetically Accessible", x: width * 0.6, y: height * 0.6, rx: 100, ry: 60, color: "rgba(153, 102, 255, 0.2)", textX: width * 0.6 + 120, textY: height * 0.6 }, // Right of oval
      { label: "Non Toxic", x: width * 0.5, y: height * 0.5, rx: 130, ry: 100, color: "rgba(255, 159, 64, 0.2)", textX: width * 0.5, textY: height * 0.5 + 120 }, // Bottom of oval
    ];

    // Draw ovals
    ovals.forEach((oval) => {
      ctx.beginPath();
      ctx.ellipse(oval.x, oval.y, oval.rx, oval.ry, 0, 0, 2 * Math.PI);
      ctx.fillStyle = oval.color;
      ctx.fill();
      ctx.strokeStyle = oval.color.replace("0.2", "0.8");
      ctx.stroke();
    });

    // Draw labels
    ovals.forEach((oval) => {
      ctx.font = "12px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.fillText(oval.label, oval.textX, oval.textY);
    });
  },
};

// Data for points (modifications to the lead compound)
const data = {
  datasets: [
    {
      label: "Lead Modifications",
      data: [
        { x: 540, y: 300 }, // Initial lead
        { x: 480, y: 380 }, // Modification 1
        { x: 400, y: 360 }, // Modification 2
        { x: 450, y: 240 }, // Modification 3
        { x: 400, y: 298 }, // Final drug-like compound
      ],
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      borderColor: "rgba(0, 0, 0, 1)",
      pointRadius: 5,
      pointHoverRadius: 7,
    },
  ],
};

// Chart options
const options = {
  scales: {
    x: { display: false, min: 0, max: 800 }, // Hide x-axis
    y: { display: false, min: 0, max: 600 }, // Hide y-axis
  },
  plugins: {
    legend: { display: false }, // Hide legend
    tooltip: { enabled: true }, // Enable tooltips
  },
};

// Main component
const DrugOptimizationChart = () => {
  return (
    <div style={{ width: "800px", height: "400px", margin: "auto" }}>
      <Chart
        type="scatter"
        data={data}
        options={options}
        plugins={[ovalPlugin]}
      />
    </div>
  );
};


const DrugDiscoveryFunnel = () => {
  // Define a color scheme for the funnel sections
  const colors = ["#ff6347", "#ffa07a", "#f4a460", "#4682b4", "#3cb371", "#8a2be2", "#ff4500", "#2e8b57"];

  const funnelData = [
    { stage: "Target Validation", cycleTime: "1.5 y", costPerNME: "3%", successRate: null, candidates: null, value: 100, color: colors[0] },
    { stage: "Hit Screening", cycleTime: "1.5 y", costPerNME: "6%", successRate: null, candidates: "> 10,000", value: 80, color: colors[1] },
    { stage: "Lead Optimization", cycleTime: "1.5 y", costPerNME: "17%", successRate: null, candidates: "~250", value: 60, color: colors[2] },
    { stage: "Preclinical Tests", cycleTime: "1 y", costPerNME: "7%", successRate: null, candidates: "10-20", value: 40, color: colors[3] },
    { stage: "Phase 1", cycleTime: "1.5 y", costPerNME: "15%", successRate: "66.4%", candidates: "~6", value: 30, color: colors[4] },
    { stage: "Phase 2", cycleTime: "2.5 y", costPerNME: "21%", successRate: "48.6%", candidates: "~4", value: 20, color: colors[5] },
    { stage: "Phase 3", cycleTime: "2.5 y", costPerNME: "26%", successRate: "59%", candidates: "~2", value: 10, color: colors[6] },
    { stage: "Approval", cycleTime: "1.5 y", costPerNME: "5%", successRate: null, candidates: null, value: 5, color: colors[7] },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <h2>Drug Discovery Pipeline Funnel</h2>
      <ResponsiveContainer width="100%" height={800}>
        <FunnelChart>
          <Tooltip
            content={({ payload }) => {
              if (payload && payload.length > 0) {
                const data = payload[0].payload;
                return (
                  <div style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
                    <p><strong>{data.stage}</strong></p>
                    {data.cycleTime && <p>Cycle Time: {data.cycleTime}</p>}
                    {data.costPerNME && <p>Cost: {data.costPerNME}</p>}
                    {data.successRate && <p>Success Rate: {data.successRate}</p>}
                    {data.candidates && <p>Candidates: {data.candidates}</p>}
                  </div>
                );
              }
              return null;
            }}
          />
          <Funnel dataKey="value" data={funnelData} isAnimationActive>
            {funnelData.map((entry, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}

            {/* Stage Titles in the Middle */}
            <LabelList position="center" fill="#000" stroke="none" dataKey="stage" fontSize={16} fontWeight="bold" />

            {/* Number of Candidates Beneath Title */}
            <LabelList position="center" fill="#000" stroke="none" dataKey="candidates" fontSize={12} dy={20} />
          </Funnel>

          {/* Additional Info Positioned Near Each Funnel Piece */}
          {funnelData.map((entry, index) => (
            <text
              key={index}
              x={500} // Adjusted x position
              y={140 + index * 80} // Adjusted y position
              fill="#000"
              fontSize={12}
              textAnchor="start"
            >
              {entry.cycleTime && `Cycle Time: ${entry.cycleTime}`}
              {entry.costPerNME && <tspan x={500} dy={14}>{`Cost: ${entry.costPerNME}`}</tspan>}
              {entry.successRate && <tspan x={500} dy={14}>{`Success Rate: ${entry.successRate}`}</tspan>}
            </text>
          ))}
        </FunnelChart>
      </ResponsiveContainer>
      <p>
        Source: <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9293739/" target="_blank" rel="noopener noreferrer" className="custom-link">Why 90% of clinical drug development fails and how to improve it</a>
      </p>
    </div>
  );
};

const GlobalCategoriesHistogram = () => {
  const data = {
    labels: [
      "Cancer diseases", 
      "Fetal diseases", 
      "Genetic diseases", 
      "Infectious diseases", 
      "Metabolic diseases", 
      "Rare diseases"
    ],
    datasets: [
      {
        label: "Number of Diseases",
        data: [3860, 5713, 9579, 1043, 2947, 13799],  // The data you provided
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1
      }
    ]
  };

  return (
    <div>
      <h2>Global Categories</h2>
      <Bar data={data} options={{ responsive: true }} />
      <p>
        Source: <a href="https://www.malacards.org/" target="_blank" rel="noopener noreferrer">MalaCards</a>
      </p>
    </div>
  );
};

const AnatomicalCategoriesHistogram = () => {
  const data = {
    labels: [
      "Blood diseases",
      "Bone diseases",
      "Cardiovascular diseases",
      "Ear diseases",
      "Endocrine diseases",
      "Eye diseases",
      "Gastrointestinal diseases",
      "Immune diseases",
      "Liver diseases",
      "Mental diseases",
      "Muscle diseases",
      "Nephrological diseases",
      "Neuronal diseases",
      "Oral diseases",
      "Reproductive diseases",
      "Respiratory diseases",
      "Skin diseases",
      "Smell/Taste diseases"
    ],
    datasets: [
      {
        label: "Number of Diseases",
        data: [
          2452, 3394, 2300, 2113, 2398, 4017, 1769, 2385, 1190, 3297, 2129,
          2435, 8422, 1811, 1746, 2167, 3015, 295
        ],  // The data you provided
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1
      }
    ]
  };

  return (
    <div>
      <h2>Anatomical Categories</h2>
      <Bar data={data} options={{ responsive: true }} />
      <p>
        Source: <a href="https://www.malacards.org/" target="_blank" rel="noopener noreferrer">MalaCards</a>
      </p>
    </div>
  );
};


const LazyLoadReactomeWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const widgetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // Adjust this value to control when the widget should load
      }
    );

    if (widgetRef.current) {
      observer.observe(widgetRef.current);
    }

    return () => {
      if (widgetRef.current) {
        observer.unobserve(widgetRef.current);
      }
    };
  }, []);

  return (
    <div ref={widgetRef}>
      {isVisible && <ReactomeWidget />}
    </div>
  );
};

const ReactomeWidget = () => {
  useEffect(() => {
    // Create the iframe element
    const iframe = document.createElement("iframe");
    iframe.id = "reactomeIframe";
    iframe.src = "https://reactome.org/PathwayBrowser/"; // Example URL to a pathway or Fireworks page
    iframe.width = "100%";
    iframe.height = "100%";
    iframe.frameBorder = "0";
    iframe.allowFullScreen = true;

    // Append the iframe to the container
    const container = document.getElementById("fireworksHolder");
    if (container) {
      // Append the iframe
      container.appendChild(iframe);
    }

    return () => {
      // Clean up the iframe when the component is unmounted
      const iframe = document.getElementById("reactomeIframe");
      if (iframe) {
        iframe.remove();
      }
    };
  }, []);

  return (
    <div
      id="fireworksHolder"
      style={{
        width: "930px",
        height: "500px",
        outline: "none", // Prevent focus outline
      }}
    ></div>
  );
};

const ClinicalFailurePieChart = () => {
  const data = {
    labels: [
      'Lack of Clinical Efficacy (40-50%)',
      'Unmanageable Toxicity (30%)',
      'Poor Drug-Like ADME Properties (10-15%)',
      'Lack of Commercial Needs and Poor Strategic Planning (10%)',
    ],
    datasets: [
      {
        data: [45, 30, 12.5, 12.5], // Average of the ranges
        backgroundColor: ['#FF5733', '#FFBD33', '#33FF57', '#337BFF'],
        hoverBackgroundColor: ['#FF4D00', '#FF9E00', '#2D9F00', '#0067B8'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows manual sizing
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`,
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: 'auto' }}> {/* Adjust size */}
      <h2 style={{ textAlign: 'center', fontSize: '1.2rem' }}>
        Reasons for Clinical Trial Failures (2010-2017)
      </h2>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Pie data={data} options={options} />
      </div>
      <p style={{ textAlign: 'center', fontSize: '0.9rem' }}>
        Source:  
        <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9293739/" target="_blank" rel="noopener noreferrer" className="custom-link">
          Why 90% of clinical drug development fails and how to improve it
        </a>
      </p>
    </div>
  );
};



const Summary = () => {
  const [totalApprovedDrugs, setTotalApprovedDrugs] = useState(null);
  const stageRef = useRef(null);
  const trajStageRef = useRef(null);  // Trajectory viewer stage
  const [ligands, setLigands] = useState([]);
  const [ligand, setLigand] = useState('');
  const [structureComponent, setStructureComponent] = useState(null);
  const [pdbId, setPdbId] = useState('5mai'); // Default PDB ID
  const [stage, setStage] = useState(null); // Track the NGL stage
  const [interactions, setInteractions] = useState({
    hydrophobic: true,
    hydrogenBond: true,
    weakHydrogenBond: true,
    waterHydrogenBond: true,
    backboneHydrogenBond: true,
    halogenBond: true,
    metalInteraction: true,
    saltBridge: true,
    cationPi: true,
    piStacking: true,
  });

  let trajectoryRef = useRef(null);  // Ref to store the trajectory object

  const ligandSele = "( not polymer or not ( protein or nucleic ) ) and not ( water or ACE or NH2 )";

  // Function to load a structure
  const loadStructure = (pdbId) => {

    // Create a new stage
    const newStage = new NGL.Stage(stageRef.current, {
      backgroundColor: 'white',
    });

    newStage.loadFile(`rcsb://${pdbId}`).then((component) => {
      component.addRepresentation('cartoon', { sele: 'protein' });
      component.addRepresentation('ball+stick', { sele: 'ligand' });

      component.autoView();
      component.setVisibility(true);

      // Extract ligand information with filtering
      const ligands = [];
      component.structure.eachResidue((residue) => {
        const selection = new NGL.Selection(ligandSele);
        if (selection.test(residue)) {
          const sele = `${residue.resno}${residue.inscode ? `^${residue.inscode}` : ''}:${residue.chainname}`;
          const name = `[${residue.resname}] ${sele}`;
          ligands.push({ sele, name });
        }
      });

      setLigands(ligands);
      setStructureComponent(component);
      setStage(newStage); // Update the stage state
    }).catch((error) => {
      console.error('Error loading PDB file:', error);
    });
  };

  useEffect(() => {
    // Load initial structure and trajectory
    loadStructure(pdbId);
    const trajFilePath = '/md_1u19_trj.gro'; // Adjust the trajectory file path accordingly
    loadTrajectory(trajFilePath);
  }, []); // Empty dependency array to run only once on mount

  const loadTrajectory = (trajFilePath) => {
    const trajStage = new NGL.Stage(trajStageRef.current, { backgroundColor: 'white' });
  
    trajStage.loadFile(trajFilePath, { asTrajectory: true }).then((structureComponent) => {
      structureComponent.addTrajectory(); // Add trajectory to the structure component
      structureComponent.addRepresentation('cartoon'); // Add representation for the structure
      structureComponent.addRepresentation('helixorient'); // Additional representation for helices
      structureComponent.addRepresentation('line', { sele: 'not hydrogen and sidechainAttached' }); // Line representation for sidechains
      trajStage.autoView(); // Auto view the loaded structure and trajectory
      
      trajectoryRef.current = structureComponent; // Store the structure component in ref
      playTrajectory(); // Automatically start playing the trajectory
    }).catch((error) => {
      console.error('Error loading trajectory file:', error);
    });
  };

  
  useEffect(() => {
    const fetchApprovedDrugsCount = async () => {
      try {
        const response = await fetch(
          'https://www.ebi.ac.uk/chembl/api/data/molecule.json?molecule_properties.full_mwt__isnull=false&max_phase=4&limit=1'
        );
  
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
        const data = await response.json();
        console.log('ChEMBL response:', data); // Log full response to inspect structure
  
        if (data && data.page_meta && data.page_meta.total_count) {
          setTotalApprovedDrugs(data.page_meta.total_count);
        } else {
          console.error('Unexpected response structure:', data);
        }
      } catch (error) {
        console.error('Error fetching approved drug count:', error);
      }
    };
  
    fetchApprovedDrugsCount();
  }, []);
  

  const playTrajectory = () => {
    if (trajectoryRef.current) {
      const traj = trajectoryRef.current.trajList[0].trajectory; // Access the trajectory from trajList
      const player = new NGL.TrajectoryPlayer(traj, { loop: true }); // Initialize TrajectoryPlayer with looping enabled
      traj.setPlayer(player); // Set the player on the trajectory
      player.play(); // Start playing the trajectory
    }
  };



  // Handle loading a new structure when the button is clicked
  const handleLoadStructure = () => {

    if (stage) {
      stage.removeAllComponents()
      stageRef.current.innerHTML = '';
      setStage(null); // Reset the stage state
      setStructureComponent(null); // Reset the structure component
      setLigands([]); // Clear the ligands
      setLigand(''); // Clear the selected ligand
    }
    if (pdbId.trim() === '') {
      alert('Please enter a valid PDB ID.');
      return;
    }
    loadStructure(pdbId);
  };

  const showLigand = (sele) => {
    if (!structureComponent || !sele) return;

    const structure = structureComponent.structure;

    // Get the view for the selected ligand
    const ligandView = structure.getView(new NGL.Selection(sele));

    // Check if the ligand view is valid
    if (!ligandView || ligandView.atomCount === 0) {
      console.error('Invalid ligand selection:', sele);
      alert('Invalid ligand selection. Please select a valid ligand.');
      return;
    }

    // Calculate pocket radius with fallback
    let pocketRadius;
    try {
      const min = ligandView.boundingBox.min;
      const max = ligandView.boundingBox.max;
      const size = {
        x: max.x - min.x,
        y: max.y - min.y,
        z: max.z - min.z,
      };
      const length = Math.sqrt(size.x ** 2 + size.y ** 2 + size.z ** 2);
      pocketRadius = Math.max(length / 2, 2) + 5;
    } catch (error) {
      console.error('Error calculating pocket radius:', error);
      pocketRadius = 10; // Default value
    }

    // Get atoms within 5Å of the selected ligand
    const withinSele = structure.getAtomSetWithinSelection(new NGL.Selection(sele), 5);
    const withinGroup = structure.getAtomSetWithinGroup(withinSele);
    const expandedSele = withinGroup.toSeleString();

    // Define neighbor selection
    const withinSele2 = structure.getAtomSetWithinSelection(new NGL.Selection(sele), pocketRadius + 2);
    const neighborSele2 = `(${withinSele2.toSeleString()}) and not (${sele}) and polymer`;

    // Update representations
    structureComponent.removeAllRepresentations();

    structureComponent.addRepresentation('cartoon', { sele: 'protein', visible: false });
    structureComponent.addRepresentation('ball+stick', {
      sele: sele,
      multipleBond: 'symmetric',
      colorValue: 'grey',
      aspectRatio: 1.2,
      radiusScale: 2.5,
    });
    structureComponent.addRepresentation('ball+stick', {
      sele: expandedSele,
      aspectRatio: 1.1,
      colorValue: 'lightgrey',
      multipleBond: 'symmetric',
    });
    structureComponent.addRepresentation('contact', {
      sele: expandedSele,
      radiusSize: 0.07,
      ...interactions,
    });


    // Zoom into the ligand
    structureComponent.autoView(expandedSele, 2000);
  };

  const handleLigandChange = (event) => {
    const sele = event.target.value;
    setLigand(sele);
    showLigand(sele);
  };

  const handleInteractionChange = (event) => {
    const { name, checked } = event.target;
    setInteractions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (structureComponent && ligand) {
      showLigand(ligand);
    }
  };



  return (
    <div id="summary">
      <h2 className="head-text"> Global Health </h2>
      <p style={{   margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
      Global health encompasses the study and management of diseases that affect populations worldwide, with a focus on reducing disease burden and improving quality of life. 
      Infectious diseases, such as malaria and tuberculosis, continue to pose significant challenges in many regions, while non-communicable diseases (NCDs) like cancer, cardiovascular disease, and diabetes are rising due to aging populations and lifestyle changes. 
      As highlighted by the current prevalence, cancer diseases (3,860), metabolic disorders (2,947), and rare diseases (13,799) remain central to healthcare challenges, while conditions like blood diseases (2,452) and respiratory diseases (2,167) represent significant anatomical concerns. 
      Understanding the distribution helps prioritize research and healthcare interventions. 
      This context is essential for drug discovery, as addressing these major global health challenges requires the development of effective treatments, from antibiotics for infectious diseases to targeted therapies for conditions like cancer, to improve patient outcomes and public health.
      </p>
      <GlobalCategoriesHistogram />
      <AnatomicalCategoriesHistogram />


      <h2 className="head-text"> Drug Discovery </h2>
      <p style={{ textAlign: 'center', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
        There are approximately {totalApprovedDrugs} approved drugs worldwide, but they are far from covering the full spectrum of diseases.
      </p>
      <div>
      
      {/* Add the Drug Discovery Funnel */}
      <DrugDiscoveryFunnel />
      <ClinicalFailurePieChart />
    </div>

      <h2 className="head-text"> Computed Aided Drug Discovery </h2>
      <strong style={{ textAlign: 'center' }}>Unlocking the Secrets of Disease</strong><br></br>
      <p style={{ margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>

      Drug discovery begins with a mystery: <strong>Which molecular target holds the key to treating a disease?</strong> Scientists hunt for the most promising proteins or enzymes, unraveling their roles in intricate biological pathways. Each target is like a hidden switch—understanding how it controls disease mechanisms opens the door to powerful therapies.<br></br>
      To crack the code, researchers sift through massive datasets—genetic blueprints, protein networks, clinical insights—searching for patterns that reveal the most promising targets. It’s a fusion of cutting-edge science and detective work.<br></br>
      
      </p>
      <p style={{ margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
      Now, it's your turn to explore! <strong>Dive into the Reactome pathways network</strong> below and uncover how biological systems interconnect. 🚀🔬
      </p>

      <LazyLoadReactomeWidget />

      <h2 style={{ textAlign: 'center', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} className="text-xl font-bold mb-2">
      Understanding Molecular Interactions
    </h2>


    <p style={{ margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
  When designing or analyzing molecular interactions, understanding <strong>how ligands bind to their targets</strong> is absolutely crucial for drug design and protein engineering. The interactions you see here—such as hydrogen bonds, hydrophobic contacts, salt bridges, and π-stacking—are not just important, they play a <strong>critical role in determining binding affinity and specificity</strong>.<br /><br />
  Below is an interactive visualization where you can explore <strong>how different types of interactions contribute to molecular recognition</strong>. This widget allows us to probe the mechanisms of binding and gain valuable insights into <strong>how to enhance both binding affinity and specificity</strong>.
</p>



      <div className="viewer-container">
        <div className="controls">
          <div>
            <label>
              PDB ID:
              <input
                type="text"
                value={pdbId}
                onChange={(e) => setPdbId(e.target.value)}
                style={{ marginLeft: "8px" }}
              />
            </label>
            <button onClick={handleLoadStructure}>Load Structure</button>
          </div>
          <div>
            <label>
              <select value={ligand} onChange={handleLigandChange}>
                <option value="">Select Ligand</option>
                {ligands.map((ligand, index) => (
                  <option key={index} value={ligand.sele}>
                    {ligand.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="interaction-checkboxes">
            <label>
              <input
                type="checkbox"
                name="hydrophobic"
                checked={interactions.hydrophobic}
                onChange={handleInteractionChange}
              />
              Hydrophobic
            </label>
            <label>
              <input
                type="checkbox"
                name="hydrogenBond"
                checked={interactions.hydrogenBond}
                onChange={handleInteractionChange}
              />
              Hydrogen Bond
            </label>
            <label>
              <input
                type="checkbox"
                name="weakHydrogenBond"
                checked={interactions.weakHydrogenBond}
                onChange={handleInteractionChange}
              />
              Weak Hydrogen Bond
            </label>
            <label>
              <input
                type="checkbox"
                name="waterHydrogenBond"
                checked={interactions.waterHydrogenBond}
                onChange={handleInteractionChange}
              />
              Water Hydrogen Bond
            </label>
            <label>
              <input
                type="checkbox"
                name="backboneHydrogenBond"
                checked={interactions.backboneHydrogenBond}
                onChange={handleInteractionChange}
              />
              Backbone Hydrogen Bond
            </label>
            <label>
              <input
                type="checkbox"
                name="halogenBond"
                checked={interactions.halogenBond}
                onChange={handleInteractionChange}
              />
              Halogen Bond
            </label>
            <label>
              <input
                type="checkbox"
                name="metalInteraction"
                checked={interactions.metalInteraction}
                onChange={handleInteractionChange}
              />
              Metal Interaction
            </label>
            <label>
              <input
                type="checkbox"
                name="saltBridge"
                checked={interactions.saltBridge}
                onChange={handleInteractionChange}
              />
              Salt Bridge
            </label>
            <label>
              <input
                type="checkbox"
                name="cationPi"
                checked={interactions.cationPi}
                onChange={handleInteractionChange}
              />
              Cation-Pi
            </label>
            <label>
              <input
                type="checkbox"
                name="piStacking"
                checked={interactions.piStacking}
                onChange={handleInteractionChange}
              />
              Pi-Stacking
            </label>
          </div>
        </div>
        <div
          ref={stageRef}
          className="ngl-viewer"
          style={{ width: '800px', height: '600px' }}
        />
      </div>
      <div className="p-4 bg-gray-100 rounded-lg shadow-md">

      <h3 style={{ textAlign: 'center', width: '100%', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} className="text-lg font-semibold mt-4">
      Binding Affinity:
    </h3>

    <p className="mt-2">
      The lower the free energy change (ΔG), the stronger the binding affinity, K<sub>bind</sub>
    </p>
    <p>
      But what drives binding? Binding free energy is controlled by two key thermodynamic players:
    </p>
    <div className="text-center my-4">
      <BlockMath math="\Delta G = \Delta H - T \Delta S = -k_B T \ln K_{\text{bind}}" />
    </div>
    <ul className="list-disc ml-6">
      <li>
        <strong>Enthalpy (ΔH):</strong> Think of this as the “attraction factor” of the molecule—hydrogen bonds, electrostatic forces, and van der Waals interactions that make the binding strong. <strong>The stronger the attraction, the lower ΔH, and the more favorable the binding!</strong>
      </li>
      <li>
        <strong>Entropy (ΔS):</strong> Entropy is the system's chaos factor. While binding generally reduces disorder, <strong>hydrophobic interactions</strong> can create a new order by kicking out water molecules, actually increasing entropy and making the binding process more favorable!
      </li>
    </ul>

    <p style={{ marginTop: '20px' }}>
      By crafting these interactions with precision, scientists can <strong>boost ligand binding affinity</strong> while simultaneously reducing unwanted side effects—enhancing both <em>efficacy</em> and <em>specificity</em> in drug design and biomolecular engineering.
    </p>
    </ div>

    <div className="ngl-viewer-container">
        <h3 style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }} className="text-xl font-bold mb-2">
          Molecular Dynamics Simlulation
        </h3>
        <div
          ref={trajStageRef}
          className="ngl-viewer"
          style={{ width: '800px', height: '600px', backgroundColor: 'white' }}
        />
      </div>

      <h3 style={{ textAlign: 'center', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
  Simulations: Revolutionizing Drug Discovery
</h3>
<p>
  Simulations give us the power to predict how compounds interact with target proteins by sampling the potential energy surface (PES) and estimating binding free energies. This allows us to identify compounds with the highest affinity for the target, accelerating the identification of promising clinical candidates. By leveraging computational models, we not only prioritize compounds based on binding affinity but also consider other critical factors like ADMET properties and off-target effects. The result? A faster, more efficient drug discovery process with fewer dead-ends, saving both time and resources while boosting the likelihood of clinical success.
</p>


<div className="section">
  <h3 style={{ textAlign: 'center', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
    Multi-Property Optimization: Striking the Perfect Balance
  </h3>
  <DrugOptimizationChart/>
  <p>
    In drug discovery, achieving the perfect compound requires balancing binding affinity, specificity, ADMET properties, and synthetic accessibility. Synthetic accessibility plays a crucial role in scaling up promising compounds, but poor accessibility or lack of automation can lead to costly delays. At the same time, optimizing for one property often conflicts with others—strong binding affinity might hinder ADMET characteristics, for example. The solution lies in integrating synthetic route finding with affinity and ADMET modeling to create compounds that are not only effective but also feasible for large-scale production and clinical testing. This holistic approach ensures that the drug discovery process moves efficiently and with fewer dead-ends.
  </p>
</div>


<div className="section">
  <h3 style={{ textAlign: 'center', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}>
    Merging Physics and Data Science:
  </h3>
  <p>
    The combination of physics-based approaches like molecular dynamics (MD) simulations and cutting-edge data science techniques is revolutionizing drug discovery. MD simulations give us a detailed, molecular-level understanding of interactions, energy landscapes, and dynamics—allowing for precise predictions of binding affinities and protein-ligand interactions. At the same time, knowledge graphs and data science approaches tap into the vast world of experimental data, literature, and drug databases to connect the dots in new and exciting ways.
    By combining these physics-driven insights with big data, we’re building more accurate, holistic models that factor in both molecular-level interactions and the broader biological context.
  </p>

</div>

    </div>
    

    
  );
};

export default AppWrap(
  MotionWrap(Summary, 'app__summary'),
  'summary',
  'app__whitebg',
);