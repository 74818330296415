let timelineElements = [
  {
    id: 0,
    date: "2022 - Present",
    title: "Senior Computational Scientist at Axle Research & Technologies",
    location: "Axle Research & Technologies, NIH, NCATS",
    description: "As a Senior Computational Scientist at Axle Research & Technologies, I work on the molecular modeling team to design workflows and tools for engineering desirable molecular properties, such as binding affinity. My efforts focus on creating and refining computational methods that support drug discovery and molecular design.",
    nextdescription: "In addition to my core responsibilities, I collaborate with the National Center for Advancing Translational Sciences (NCATS) on the development of graph theoretical retro-synthetic route algorithms. In addition, a large focus of work has been applying graph based machine learning methods for tasks such as reaction yield prediciton. These efforts help synthetic chemists automate the synthesis of lead compounds, optimizing pathways in drug development.",
    finaldescription: "At Axle, we are developing robust workflows using the Common Workflow Language (CWL), which allows us to standardize and automate complex computational tasks at scale, improving efficiency and reproducibility in scientific research.",
    icon: "HHS",  // Linked to the NIH icon
    buttonText: "Learn More",
    buttonFile: "https://axleinfo.com/expertise/research/data-science/" // Update with your relevant URL or leave blank
  },
  {
    id: 1,
    title: "Ph.D. - Computational Chemistry at University of Texas at Austin",
    location: "Austin, Texas",
    description:
      "Developed and designed an automated open source molecular modelling platform called Poltype 2. Assigning correct chemical parameters is very complicated! This tool has enabled researchers world wide to run simulations on protein-ligand, DNA/RNA-ligand systems with the AMOEBA model towards more accurate chemical property predictions. ",
    otherbuttonText: "Poltype 2",
    otherbuttonFile: "https://github.com/TinkerTools/poltype2",
    anotherbuttonText: "AMOEBA",
    anotherbuttonFile: "https://pubs.acs.org/doi/10.1021/jp910674d",
    date: "August 2017 - December 2022",
    icon: "texas",
  },
  {
    id: 2,
    title: "Machine Learning Engineer Intern - Sandia National Labs",
    location: "Albuquerque, New Mexico",
    description:
      "Automated database generation of ab initio quantum data for training a neural network to predict molecular electron densities towards applications of quantum accurate biomolecular simulations.",
    date: "June 2020 - August 2020",
    icon: "sandia",
  },
  {
    id: 3,
    title: "Masters - Biomedical Engineering at University of Texas at Austin",
    location: "Austin, Texas",
    description:
      "GPA=3.79.",
    buttonText: "Transcripts",
    buttonFile: "/transcripts/uttranscript.pdf",
    date: "August 2017 - May 2019",
    icon: "texas",
  },
  {
    id: 4,
    title: "B.S. - Physics & Mathematics at Purdue University",
    location: "West Lafayette, IN",
    description:
      "GPA=3.76.",
    buttonText: "Transcripts",
    buttonFile: "/transcripts/purduetranscript.pdf",
    date: "August 2013- May 2017",
    icon: "purdue",
  },

];

export default timelineElements;
